body {
  font-family: 'Lora', serif;
  background: #292e49 !important;
  background: -moz-linear-gradient(
    top,
    #292e49 0%,
    #2e5fad 50%,
    #292e49 100%
  ) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #292e49),
    color-stop(50%, #2e5fad),
    color-stop(100%, #292e49)
  ) !important;
  background: -webkit-linear-gradient(
    top,
    #292e49 0%,
    #2e5fad 50%,
    #292e49 100%
  ) !important;
  background: -o-linear-gradient(
    top,
    #292e49 0%,
    #2e5fad 50%,
    #292e49 100%
  ) !important;
  background: -ms-linear-gradient(top, #292e49 0%, #2e5fad 50%, #292e49 100%);
  background: linear-gradient(
    to bottom,
    #292e49 0%,
    #2e5fad 50%,
    #292e49 100%
  ) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#292e49', endColorstr='#292e49', GradientType=0);
  background-attachment: fixed !important;
}

.profileImg {
  width: 350px; 
  height: 350px; 
  border-radius: 50%;
  overflow: hidden;
}


.circular-image .profileImg  {
  width: 250px; 
  height: 250px; 
  object-fit: cover;
}

@keyframes react {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes test {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(0deg);
  }
}

html {
  scroll-behavior: smooth;
}

svg {
  width: 75%;
  height: 75%;
}

.svgNames {
  font-size: 0.9rem;
}

#html {
  filter: invert(36%) sepia(66%) saturate(1638%) hue-rotate(346deg)
    brightness(92%) contrast(92%);
  animation: test infinite 20s linear;
}

#node {
  filter: invert(23%) sepia(39%) saturate(760%) hue-rotate(86deg)
    brightness(99%) contrast(86%);
  animation: test infinite 20s linear;
}

#mongo {
  filter: invert(51%) sepia(28%) saturate(898%) hue-rotate(55deg)
    brightness(94%) contrast(91%);
  animation: test infinite 20s linear;
}

#react {
  filter: invert(70%) sepia(50%) saturate(3292%) hue-rotate(145deg)
    brightness(100%) contrast(107%);
  animation: test infinite 20s linear;
}

/* #react:hover,
#node:hover,
#python:hover {
  animation: react infinite 20s linear;
} */

#firebase {
  filter: invert(62%) sepia(19%) saturate(4963%) hue-rotate(1deg)
    brightness(100%) contrast(103%);
  animation: test infinite 20s linear;
}

#js {
  filter: invert(83%) sepia(92%) saturate(7267%) hue-rotate(338deg)
    brightness(101%) contrast(94%);
  animation: test infinite 20s linear;
}

#jquery {
  filter: invert(22%) sepia(98%) saturate(5891%) hue-rotate(193deg)
    brightness(93%) contrast(94%);
  animation: test infinite 20s linear;
}

#mysql {
  filter: invert(22%) sepia(66%) saturate(2991%) hue-rotate(172deg)
    brightness(98%) contrast(101%);
  animation: test infinite 20s linear;
}

#python {
  filter: invert(42%) sepia(0%) saturate(1972%) hue-rotate(168deg)
    brightness(87%) contrast(80%);
  animation: test infinite 20s linear;
}

#django {
  filter: invert(12%) sepia(24%) saturate(1270%) hue-rotate(106deg)
    brightness(102%) contrast(96%);
  animation: test infinite 20s linear;
}

#git {
  filter: invert(0%) sepia(1%) saturate(4159%) hue-rotate(325deg)
    brightness(104%) contrast(60%);
  animation: test infinite 20s linear;
}

#css {
  filter: invert(24%) sepia(90%) saturate(2241%) hue-rotate(223deg)
    brightness(88%) contrast(103%);
  animation: test infinite 20s linear;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.card-text {
  font-size: 0.93rem;
}

.anchor {
  display: block;
  height: 56px;
  margin-top: -56px;
  visibility: hidden;
}

.badge-info {
  background-color: #292e49 !important;
  font-size: 22px;
}

.card {
  width: 100%;
}

.card-img-top {
  height: 70% !important;
}

.card-title {
  font-size: 22px;
  text-decoration: underline;
}

.icon-huge {
  font-size: 50px;
}

.navbar,
.navbar-toggler {
  background: #292e49 !important;
  background-color: #292e49 !important;
}

#navbarNav {
  background-color: #292e49 !important;
  background: #292e49 !important;
}

/* .navbar-brand {
  letter-spacing: 1.5px;
  border: 1px;
  border-style: solid;
  border-color: rgb(248, 249, 250, 0.1) !important;
  font-size: 20px;
  border-radius: 0.5px;
  box-shadow: 0px 0px 0px 8px rgba(248, 249, 250 0.1);
} */

.sounds-button {
  margin-left: 2px;
}

/* .spacer {
  flex: 1;
} */

.project {
  margin-bottom: -10px;
}

.sticky-footer {
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 10px;
  font-size: 10px;
  position: sticky !important;
  bottom: 0px;
  z-index: 0;
  background: #292e49;
}

.icon-large {
  font-size: 36px;
  margin-right: 15px;
}

.link:hover {
  color: #f8f9fa !important;
}

/* .svg-img {
  filter: invert(18%) sepia(14%) saturate(1403%) hue-rotate(193deg) brightness(94%) contrast(98%)
} */

/* .tech-btn {
  border-color: #292e49 !important;
  color: #292e49 !important;
}

.tech-btn:hover {
  background-color: #292e49 !important;
  color: #f8f9fa !important;
}

.tech-btn:focus,
.tech-btn:active {
  background-color: #292e49 !important;
  color: #f8f9fa !important;
  outline: none !important;
  box-shadow: none !important;
} */

#linked-in {
  padding-left: 24px;
  padding-right: 24px;
}

.linked-size {
  font-size: 14px;
}

.tech-used {
  cursor: pointer;
}

@media screen and (max-width: 990px) {
  /* .linked-in {
    margin-left: 25%;
    margin-right: 25%;
  } */
  .tech-btn:focus,
  .tech-btn:active {
    background-color: transparent;
    color: #28a745;
  }

  .anchor {
    display: block;
    height: 216px;
    margin-top: -216px;
    visibility: hidden;
  }

  .svgNames {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 570px) {
  .hvr-grow:hover,
  .hvr-grow:focus,
  .hvr-grow:active {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .project-card {
    margin-top: 0.6rem !important;
    margin-bottom: 0.6rem !important;
  }

  .contact-card {
    margin-top: 0.6rem !important;
  }

  .svgNames {
    font-size: 0.65rem;
  }
}
